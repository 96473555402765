import Vue from "vue";
import Router from "vue-router";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "HomeView",
      redirect: { name: "SchedulePlacesView" },
      meta: {
        auth: true,
      }
    },
    {
      path: "/places",
      name: "PlacesListView",
      component: () => import("@/views/PlacesListView"),
      meta: {
        auth: true,
      }
    },
    {
      path: "/places/create",
      name: "PlacesCreateView",
      component: () => import("@/views/PlacesCreateView"),
      meta: {
        auth: true,
      }
    },
    {
      path: "/places/:id",
      name: "PlacesEditView",
      component: () => import("@/views/PlacesEditView"),
      props: true,
      meta: {
        auth: true,
      }
    },
    {
      path: "/schedule",
      name: "SchedulePlacesView",
      component: () => import("@/views/SchedulePlacesView"),
      meta: {
        auth: true,
      }
    },
    {
      path: "/schedule/:place/:year/:month/:day",
      name: "ScheduleTableView",
      component: () => import("@/views/ScheduleTableView"),
      props: true,
      meta: {
        auth: true,
      }
    },
    {
      path: "/users/:id",
      name: "UsersShowView",
      component: () => import("@/views/UsersShowView"),
      props: true,
      meta: {
        auth: true,
      }
    },
    {
      path: "/login",
      name: "LoginView",
      component: () => import("@/views/LoginView")
    },
    {
      path: '*',
      redirect: { name: "Schedule" },
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !localStorage.auth_token) {
    next('/login')
  } else {
    next()
  }
})

export default router